import { css } from "@emotion/react";
import styled from "@emotion/styled";
import colors from "@variables/colors";
import { FONTSIZE_13, FONTSIZE_6 } from "@variables/font-sizes";
import { mdLayout } from "@variables/layout";
import { PADDING_4 } from "@variables/paddings";
import { Link } from "gatsby";
import { container } from "@assets/style/GeneralStyleComponent";
import AppImage from "./AppImage";
import Icon from "./Icon";
import Typography from "./Typography";

interface Props {
  title: string;
  image: string;
}
const BlogHeader = ({ title, image }: Props) => {
  return (
    <Wrapper>
      <Link css={linksCss} to="/blog">
        <Icon name="LeftArrow" />
        <span>Back To Blogs</span>
      </Link>

      <Header>
        <Typography css={titleCss} as="h2" content={title} />
        <AppImage css={imageCss} src={image} alt={`${title}-image`} />
      </Header>
    </Wrapper>
  );
};

export default BlogHeader;

const linksCss = css`
  position: absolute;
  top: 160px;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 0.3em;
  font-size: ${FONTSIZE_6};
  &,
  &:hover {
    color: ${colors.gray[200]};
  }
  .icon {
    color: ${colors.black};
  }

  @media ${mdLayout} {
    position: static;
    padding-bottom: ${PADDING_4};
  }
`;

const Wrapper = styled.div`
  ${container}
  padding-top: 160px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 400px;
`;

const titleCss = css`
  color: ${colors.gray[500]};
  font-size: ${FONTSIZE_13};
  width: 50%;
  max-width: 16ch;
`;

const imageCss = css`
  width: 35%;
`;
